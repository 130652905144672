import React from "react";
import { Image } from "../../utils/helper";
import './3logosection.scss';

const ThreeLogoSection = (props) => {
    const { company_logos, end_description, end_title } = props
    return (
        <div className='logosection-cover'>
            <div className='trusted-team'>
                <h6> {end_title} </h6>
                <div className='trusted-logo-container'>
                    {company_logos?.map(item => (<Image src={item?.Image?.filename} alt={item?.Image?.alt} />))}
                </div>
                <p> {end_description} </p>
            </div>
        </div>
    )
}

export default ThreeLogoSection