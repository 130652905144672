import React from "react";
import { Image } from "../../utils/helper";
import ThreeLogoSection from "../3LogoSection";
import './thanks.scss';

const Thanks = ({ story, location }) => {
    const { content: { title, image, description, Sections } } = story

    return (
        <div className='thanks-section'>
            <Image src={image?.filename} alt={image?.alt} />
            <div className='thanks-info'>
            <h4> { title } </h4>
            <p> {description} </p>
            </div>
            { Sections?.map( item => <ThreeLogoSection {...item}/>) }
        </div>
    )
}

export default Thanks